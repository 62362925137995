/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { useAddItemPopup } from '../../../../hooks/useAddItemPopup'
import { TGalleryItemInfo } from '../../../../store/gallery/types'
import { IconCircleMinus } from '../../../../svg/IconCircleMinus'
import { IconCirclePlus } from '../../../../svg/IconCirclePlus'
import { IconDollar } from '../../../../svg/IconDollar'
import { IconPercent } from '../../../../svg/IconPercent'
import { BidStatus } from '../../../../types/actions'
import { convertToDollars } from '../../../../utils/convertToDollars'
import { ERROR_TEXT } from '../../../../utils/galleryUtils/consts'
import { addBidAction, deleteBidAction } from '../../../../utils/galleryUtils/itemsActions'
import { Spinner } from '../../../../svg/Spinner'

const InputWrappers = styled.input`
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: textfield;
  }
`

interface BidWidgetDiamondProps {
  item: TGalleryItemInfo
  tenderId: number
  bidFromTable?: number
  setPurchasePopupActive: (value: boolean) => void
}

export const BidWidgetDiamond: React.FC<BidWidgetDiamondProps> = ({
  item,
  setPurchasePopupActive,
  bidFromTable,
  tenderId,
}) => {
  const dispatch = useDispatch()
  const { remove } = useAddItemPopup()
  const [showError, setShowError] = useState(false)

  const getBid = () => {
    if (item.bid) return Math.round(item.bid / item.product.product_details.weight / 100)
    if (!item.bid && bidFromTable)
      return Math.round((item.product.rap_list_price / 100) * ((100 - -bidFromTable) / 100))

    return 0
  }

  const [bid, setBid] = useState(getBid())
  const [editBidWidget, seEditBidWidget] = useState(!item.bid)
  const [disableBidButton, seDisableBidButton] = useState(false)
  const [bidStatus, setBidStatus] = useState<BidStatus>('bid')
  const [bidLoading, setBidLoading] = useState(false)
  const [bidDeleteLoading, setBidDeleteLoading] = useState(false)

  const [enterDiscount, setEnterDiscount] = useState(bid > item.product.rap_list_price / 100)

  const getPercent = Math.abs(Math.round(((getBid() / (item.product.rap_list_price / 100)) * 100 - 100) * 100) / 100)
  const getBidPercent = bid > 0 ? getPercent : ''

  const [bidPercent, setBidPercent] = useState(getBidPercent)
  const [percentError, setPercentError] = useState(false)

  useEffect(() => {
    if (bidFromTable) setBidStatus('discount')
  }, [])

  useEffect(() => {
    let timer: any
    if (bidPercent === '.') {
      seDisableBidButton(false)
    }

    if (+bidPercent) {
      const bidSum = enterDiscount
        ? Math.round((1 + +bidPercent / 100) * (item.product.rap_list_price / 100))
        : Math.round((1 - +bidPercent / 100) * (item.product.rap_list_price / 100))
      const roundedBid = Math.abs(Math.round(((bidSum / (item.product.rap_list_price / 100)) * 100 - 100) * 100) / 100)

      timer = setTimeout(() => {
        setBidPercent(roundedBid)
        setBid(bidSum)
        seDisableBidButton(false)
      }, 800)
    }

    if (bidPercent === 0) {
      timer = setTimeout(() => {
        setBidPercent(0)
        setBid(0)
        seDisableBidButton(false)
      }, 800)
    }

    return () => clearTimeout(timer)
  }, [bidPercent])

  useEffect(() => {
    if (!editBidWidget) {
      setBidPercent(getBidPercent)
    }
  }, [item.bid, editBidWidget])

  const changeInputValue = (event: any) => {
    if (event.code === 'ArrowUp') {
      setBidPercent((prev) => Math.round((+prev + 0.01) * 100) / 100)
    }
    if (event.code === 'ArrowDown') {
      setBidPercent((prev) => (+prev > 0 ? Math.round((+prev - 0.01) * 100) / 100 : 0))
    }
    seDisableBidButton(true)
  }

  const onPlaceBidClick = () => {
    setBidLoading(true)
    addBidAction(
      () => {
        remove()
        setBidLoading(false)
      },
      () => {
        setShowError(true)
        setBidLoading(false)
      },
      tenderId,
      item,
      bid,
      item.bid,
      Math.round(bid * item.product.product_details.weight * 100),
      dispatch,
      bidStatus
    )
  }

  const onDeleteBidClick = () => {
    setBidDeleteLoading(true)
    deleteBidAction(
      () => {
        remove()
        setBidDeleteLoading(false)
      },
      () => {
        setShowError(true)
        setBidDeleteLoading(false)
      },
      tenderId,
      item,
      dispatch
    )
  }

  const bidButtonDisabled =
    bid < 1 ||
    !bid ||
    Math.round(bid * item.product.product_details.weight * 100) / 100 === item.bid / 100 ||
    Math.round(bid * item.product.product_details.weight) >= item.product.asking_price / 100 ||
    percentError ||
    bid === item.product.rap_list_price / 100 ||
    disableBidButton ||
    bidLoading ||
    bidDeleteLoading

  return (
    <div className='bg-slate-50 lg:w-11/12 p-3 smallMobile:px-6 md:px-3 lg:px-6'>
      {!item.bid ? null : (
        <div className='bid-status'>
          <div className='uppercase font-semibold flex mb-2'>Your company bid:</div>
          <div className='text-slate-600 text-lg smallMobile:text-xl font-semibold flex divide-x '>
            <div className='pr-3'>{`${convertToDollars(item.bid / item.product.product_details.weight)} /ct`}</div>
            {item.product.rap_list_price ? (
              <div className='px-2'>{`${getBid() > item.product.rap_list_price / 100 ? '' : '-'}${getPercent}%`}</div>
            ) : null}
            <div className='pl-3'>{`${convertToDollars(item.bid)}`}</div>
          </div>
        </div>
      )}
      {item.bid === 0 || editBidWidget ? (
        <div className='mt-2'>
          <div className='uppercase font-semibold flex mb-2'>
            <div className=''>Enter bid</div>
          </div>
          <div className='flex-col mb-3 items-center '>
            {item.product.rap_list_price ? (
              <div className='discount mb-4'>
                <div className='flex justify-between space-x-3 p-3 rounded border-bluegray-200 border items-center bg-white'>
                  <button
                    className='discount leading-none text-lightblue-600 text-2xl'
                    type='button'
                    onClick={() => {
                      setEnterDiscount(!enterDiscount)
                      setBid(0)
                      setBidPercent('')
                    }}
                  >
                    {enterDiscount ? <IconCirclePlus /> : <IconCircleMinus />}
                  </button>

                  <InputWrappers
                    type='text'
                    value={bidPercent}
                    id={`input-popup-${item.item_id}`}
                    className='discount w-full text-base input-plain outline-none appearance-none'
                    placeholder='Enter Discount / Premium'
                    onFocus={() => {
                      const targetInput = document.getElementById(`input-popup-${item.item_id}`)
                      if (targetInput) targetInput.addEventListener('keydown', (event) => changeInputValue(event))
                    }}
                    onChange={(e) => {
                      const { value } = e.target
                      setBidStatus('discount')

                      const regex = /^[0-9.,]+$/

                      const vv = value.replace(',', '.')
                      if (
                        (!value.match(regex) && value !== '') ||
                        (value.slice(0, -1).includes('.') && value.replace(',', '.')[value.length - 1] === '.')
                      ) {
                        e.preventDefault()
                        seDisableBidButton(false)
                      } else if (value.length > 1 && value[1] !== '.' && value[1] !== ',' && value[0] === '0') {
                        setBidPercent(+`${value[0]}.${value.slice(1)}`)
                        seDisableBidButton(true)
                      } else if (value.split('.')[1]?.length > 2) {
                        e.preventDefault()
                      } else if ((+vv >= 0 && value !== '') || value === '.') {
                        setPercentError(false)
                        setBidPercent(vv)
                        seDisableBidButton(true)
                      } else {
                        setBid(0)
                        setBidPercent('')
                      }
                    }}
                  />

                  <div className='text-bluegray-500 text-base'>
                    <IconPercent />
                  </div>
                </div>
                <div className='italic text-bluegray-500 text-xs mt-2'>
                  To place a bid above RAP List Price click the - button to change to +
                </div>
              </div>
            ) : null}

            <div className='carat mb-6'>
              <div className='flex justify-between space-x-3 p-3 rounded border-bluegray-200 border items-center bg-white'>
                <div className='leading-none text-bluegray-500 text-2xl'>
                  <IconDollar />
                </div>

                <input
                  type='text'
                  className='price-per-carat-input w-full text-base input-plain outline-none'
                  placeholder='Enter Price Per Carat'
                  value={bid > 0 ? bid : ''}
                  onChange={(e) => {
                    const value = parseFloat(e.target.value)
                    setBidStatus('bid')
                    if (value >= 1) {
                      const percent =
                        value > item.product.rap_list_price / 100
                          ? Math.round(((value / (item.product.rap_list_price / 100)) * 100 - 100) * 100) / 100
                          : Math.round((100 - (value / (item.product.rap_list_price / 100)) * 100) * 100) / 100
                      setEnterDiscount(value > item.product.rap_list_price / 100)
                      setBid(value)
                      setBidPercent(percent)
                    } else {
                      setBid(0)
                      setBidPercent('')
                    }
                  }}
                />

                <div className='leading-none text-bluegray-500 whitespace-nowrap text-base font-semibold'>/ ct</div>
              </div>

              <div className='bid-error mt-1'>
                {item.product.rap_list_price && !enterDiscount && +bidPercent > 99.99 ? (
                  <div className='text-red-500 text-sm'>{ERROR_TEXT.shouldBeBetween}</div>
                ) : null}

                {item.product.asking_price &&
                Math.round(bid * item.product.product_details.weight) >= item.product.asking_price / 100 ? (
                  <div className='text-red-500 text-sm'>{ERROR_TEXT.bidHigher}</div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {item.bid && editBidWidget ? (
        <div className='flex justify-between items-center mt-4'>
          <div className='flex-col'>
            <button
              type='button'
              className='text-slate-500 font-semibold border-b border-slate-300 border-dotted border-opacity-80 pb-1 hover:text-slate-700'
              onClick={() => {
                seEditBidWidget(false)
                setShowError(false)
              }}
            >
              Cancel
            </button>
          </div>
          <div className='flex-col relative'>
            {bidDeleteLoading ? (
              <div className='absolute inset-0 flex justify-center items-center z-10'>
                <Spinner classname='!text-black' />
              </div>
            ) : null}

            <button
              type='button'
              className='delete-bid text-red-500 font-semibold border-b border-slate-300 border-dotted  border-opacity-80  pb-1 hover:text-red-900'
              onClick={onDeleteBidClick}
              disabled={bidDeleteLoading || bidLoading}
            >
              Delete Bid
            </button>
          </div>

          <div className='relative w-5/12'>
            {bidLoading ? (
              <div className='absolute inset-0 flex justify-center items-center z-10'>
                <Spinner classname='!text-sky-900' />
              </div>
            ) : null}

            <button
              type='button'
              className='save-bid text-xl w-full text-center py-1 px-3 text-white rounded-md cursor-pointer bg-sky-700 hover:bg-sky-600'
              onClick={onPlaceBidClick}
              disabled={bidButtonDisabled}
            >
              SAVE BID
            </button>
          </div>
        </div>
      ) : (
        <div className='flex justify-between items-center mt-4'>
          <div className='flex-col'>
            {item.product.asking_price ? (
              <button
                type='button'
                className='buy-now text-green-600 font-semibold border-b pb-1 hover:text-green-700 mr-2'
                onClick={() => setPurchasePopupActive(true)}
              >
                {`BUY NOW - ${convertToDollars(item.product.asking_price)}`}
              </button>
            ) : null}
          </div>
          {item.bid ? (
            <div className='flex-col'>
              <button
                type='button'
                className='edit-bid text-sky-700 font-semibold border-b border-slate-300 border-dotted  border-opacity-80  pb-1 hover:text-sky-800 uppercase'
                onClick={() => seEditBidWidget(true)}
              >
                Edit Bid
              </button>
            </div>
          ) : (
            <div className='relative w-6/12'>
              {bidLoading ? (
                <div className='absolute inset-0 flex justify-center items-center z-10'>
                  <Spinner classname='!text-sky-900' />
                </div>
              ) : null}

              <button
                type='button'
                className='place-bid text-xl w-full text-center py-1 px-3 text-white rounded-md cursor-pointer bg-sky-700 hover:bg-sky-600'
                onClick={onPlaceBidClick}
                disabled={bidButtonDisabled}
              >
                PLACE BID
              </button>
            </div>
          )}
        </div>
      )}
      {showError ? (
        <div className='text-red-500 w-full mt-3 text-lg'>
          You do not have access to bid on CIRCA tenders. Please contact{' '}
          <a href='mailto:tender@circajewels.com' className=' underline hover:text-red-700'>
            tender@circajewels.com
          </a>{' '}
          to become a verified user.
        </div>
      ) : null}
    </div>
  )
}
