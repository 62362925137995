import { NewGallerySnackBar } from '../../components/ui/galleryUi/NewGallerySnackBar'
import { SetItemRecommendation, SetItemWishlist } from '../../store/gallery/actions'
import { DeleteItemBid, SetItemBid, SetItemBuy } from '../../store/gallery/tendersActions/actions'
import { TGalleryItemInfo } from '../../store/gallery/types'
import { BidStatus, TDefRequest } from '../../types/actions'

const { addNewGallerySnackBar } = NewGallerySnackBar()

export const addToRecommendedAction = (
  isRecommended: boolean,
  companyId: number,
  idItem: number,
  dispatch: any,
  callback?: TDefRequest['callBack']
) => {
  dispatch(
    SetItemRecommendation.request({
      companyId: [companyId],
      idItem,
      status: !isRecommended,
      callBack: callback,
    })
  )
}

export const addToFavoritesAction = (isFavorites: boolean, tenderId: number, itemId: number, dispatch: any) => {
  dispatch(
    SetItemWishlist.request({
      idTender: tenderId,
      idItem: itemId,
      status: !isFavorites,
    })
  )
}

export const buyItemAction = (
  buyItemCallback: () => void,
  showError: () => void,
  onItemUnavailable: () => void,
  tenderId: number,
  item: TGalleryItemInfo,
  dispatch: any
) => {
  dispatch(
    SetItemBuy.request({
      idTender: tenderId,
      idItem: item.item_id,
      callBack: (success, data) => {
        if (success) {
          buyItemCallback()
          addNewGallerySnackBar(`Your purchase ${item.product.title} has been confirmed.`, true, 'purchased')
        } else if (data && data.message) {
          if (data.message === 'do not have access') showError()
          if (data.message === 'this item is unavailable') {
            onItemUnavailable()
            addNewGallerySnackBar(`The item ${item.product.title} was already sold!`, false, 'purchase')
          }
        }
      },
    })
  )
}

export const deleteBidAction = (
  deleteBidCallback: () => void,
  showError: () => void,
  tenderId: number,
  item: TGalleryItemInfo,
  dispatch: any
) => {
  dispatch(
    DeleteItemBid.request({
      idTender: tenderId,
      idItem: item.item_id,
      callBack: (success, data) => {
        if (success) {
          deleteBidCallback()
          addNewGallerySnackBar(`Your bid ${item.product.title} has been deleted.`, success, 'deleteBid')
        } else if (data && data.message && data.message === 'do not have access') showError()
      },
    })
  )
}

export const addBidAction = (
  addBidCallback: () => void,
  showError: () => void,
  tenderId: number,
  item: TGalleryItemInfo,
  bid: number,
  itemBid: number,
  bidAmount: number,
  dispatch: any,
  status?: BidStatus
) => {
  if (bid >= 1) {
    const bidStatus: BidStatus = status || 'bid'
    dispatch(
      SetItemBid.request({
        idTender: tenderId,
        idItem: item.item_id,
        actionType: itemBid === 0 ? 'addBid' : 'editBid',
        body: {
          bid_amount: bidAmount,
          type_of_bid: bidStatus,
        },
        callBack: (success, data) => {
          if (success) {
            addBidCallback()
            if (itemBid === 0) {
              addNewGallerySnackBar(`Your bid ${item.product.title} has been placed.`, success, 'deleteBid')
            } else {
              addNewGallerySnackBar(`Your bid ${item.product.title} has been edited.`, success, 'deleteBid')
            }
          } else if (data && data.message && data.message === 'do not have access') showError()
        },
      })
    )
  }
}
